import React, { ReactNode, Fragment } from 'react'
import s from './SolutionsTwo.module.scss'
import cn from 'classnames'
import Button from '@/components/Button1'
import Image from 'next/image'
type Iprops = {
  isH5?: boolean
  title?: string
  list?: Array<any>
  wrapperBg?: string
  theme?: 'dark' | 'light' //默认dark（白色字体）  light（黑色字体）
  children?: ReactNode
  modal2Columns?: number
  hover?: boolean //整个模块是否可以hover 目前只有首页可以
  className?: string
}
const SolutionsList = (props: Iprops) => {
  const { title = '', list = [], wrapperBg = '', theme = 'light', modal2Columns = 2, hover = false, className = '' } = props
  return (
    <div className={cn(s.wrapper, className)} style={{ background: wrapperBg || '#f2f8ff' }}>
      {title && (
        <p className={cn(s.max_width, s.title, 'title2')} data-aos="fade-up">
          {title}
        </p>
      )}
      {list.map((item, index) => {
        return item.type != 2 ? (
          <div key={item.id} className={cn(s.modal, s.max_width)}>
            <div
              className={cn(s.modal_left, s.bg, { [s.light_bg]: theme == 'dark' }, s.content, { [s.modal_hover]: hover })}
              style={{ background: item?.bg || '#095BE8' }}
              onClick={() => {
                item.onClick && item.onClick()
              }}
            >
              {/* <div className={s.content}> */}
              <div className={s.content_left}>
                <div>
                  <h2 className={cn(s.content_title)} data-aos="fade-up" dangerouslySetInnerHTML={{ __html: item.title }} />
                  <p className={cn(s.content_desc, 'text3')} data-aos="fade-up">
                    {item.desc}
                  </p>
                </div>
                <div className={cn(s.btnWarp)}>
                  {item.btnText1 && (
                    <Button
                      className={cn(s.contentBtn, s.solutionsBtn1)}
                      link=""
                      text={item.btnText1}
                      type="green"
                      theme={theme}
                      size="small"
                      onClick={item?.btnClick1 && item?.btnClick1}
                      arrow={false}
                    />
                  )}
                  {item.btnText2 && (
                    <Button
                      className={cn(s.contentBtn, s.solutionsBtn1)}
                      link=""
                      text={item.btnText2}
                      type="Arrow"
                      theme={theme}
                      size="small"
                      onClick={item?.btnClick2 && item?.btnClick2}
                      arrow={false}
                    />
                  )}
                </div>
              </div>
              <div className={s.content_right} data-aos="fade-up">
                <Image layout="fill" src={item.img} alt=""></Image>
              </div>
              {/* </div> */}
            </div>
            {item.renderRightChaild ? (
              item.renderRightChaild()
            ) : (
              <div
                className={cn(s.modal_right, s.bg, { [s.light_bg]: theme == 'dark' }, { [s.modal_hover]: hover })}
                style={{ background: item?.bg1 || '' }}
                onClick={() => {
                  item.onClick1 && item.onClick1()
                }}
              >
                <div>
                  <h2 className={cn(s.content_title)} data-aos="fade-up" dangerouslySetInnerHTML={{ __html: item.title1 }} />
                  <p className={cn(s.content_desc, 'text3')} data-aos="fade-up">
                    {item.desc1}
                  </p>
                </div>
                {item.btn2Text && (
                  <Button
                    className={cn(s.contentBtn, s.solutionsBtn1)}
                    link=""
                    text={item.btnText2}
                    type="Arrow"
                    theme={theme}
                    size="small"
                    onClick={item?.btn2Click && item?.btn2Click}
                    arrow={false}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <div key={item.id} className={cn(s.modal, s.max_width, s.modal2, index % 2 == 0 ? s.modal2_even : s.modal2_odd)} style={{ gridTemplateColumns: `repeat(${modal2Columns}, 1fr)` }}>
            {item.items.map((type2: any, i: number) => (
              <div
                className={cn(s.modal_right, s.bg, { [s.light_bg]: theme == 'dark' }, { [s.modal_hover]: hover })}
                key={i}
                style={{ background: type2?.bg || '' }}
                onClick={() => {
                  type2.onClick && type2.onClick()
                }}
              >
                <div>
                  <h2 className={cn(s.content_title)} data-aos="fade-up" dangerouslySetInnerHTML={{ __html: type2.title }} />
                  {Array.isArray(type2.desc) ? (
                    <p className={cn(s.content_desc, 'text3')} data-aos="fade-up">
                      {type2.desc.map((descItem: string, desci: number) => {
                        return (
                          <Fragment key={desci}>
                            {descItem}
                            {desci != type2.desc.length - 1 && <br />}
                          </Fragment>
                        )
                      })}
                    </p>
                  ) : (
                    <p className={cn(s.content_desc, 'text3')} data-aos="fade-up">
                      {type2.desc}
                    </p>
                  )}
                </div>
                {type2.btnText && (
                  <Button
                    className={cn(s.contentBtn, s.solutionsBtn1)}
                    link=""
                    text={type2.btnText}
                    type="Arrow"
                    theme={theme}
                    size="small"
                    onClick={type2?.btnClick && type2?.btnClick}
                    arrow={false}
                  />
                )}
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}

export default SolutionsList
